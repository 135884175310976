import { inject }                     from 'aurelia-framework';
import { BaseViewModel }              from 'base-view-model';
import { LotInterventionsRepository } from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { CopyToAnotherLotModal }      from 'modules/management/concessions/lot-groups/copy-to-another-lot-modal';
import { InfoDialog }                 from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }               from 'resources/services/app-container';

@inject(AppContainer, LotInterventionsRepository)
export class BaseListViewModel extends BaseViewModel {

    filterFormIsDirty = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param lotInterventionsRepository
     */
    constructor(appContainer, lotInterventionsRepository) {
        super(appContainer);

        this.lotInterventionsRepository = lotInterventionsRepository;
    }

    /**
     * Exports the listing to Excel
     * Assumptions: a `filterModel`, `downloader` instance and repository `export` method must exist
     *
     * @param filename
     * @param selectedRows
     */
    exportListing(filename = 'listing', selectedRows = null) {
        const filterModel = Object.assign({
            sorting: this.datatable.instance.getSortingColumn(),
        }, this.filterModel);

        if (selectedRows) {
            filterModel.selectedRows = selectedRows;
        }

        this.repository.export(filterModel).then(response => this.downloader.download(response, filename, 'xlsx'));
    }

    /**
     * Export ZIP
     *
     * @returns {*}
     */
    exportZip() {
        const filterModel = Object.assign(this.filterModel || {});

        filterModel.selectedRows = this.datatable.instance.selectedRows;

        return this.repository
            .generateZip(filterModel)
            .then(response => {
                response.status === true
                    ? this.appContainer.notifier.successNotice(this.i18N.tr('text.success-message.file-being-generated-please-wait'))
                    : this.appContainer.notifier.dangerNotice(response.message);
            });
    }

    /**
     * Copy to another lot
     *
     * @param rowId
     * @param lotInterventionId
     * @param createRoute
     */
    async copyToAnotherLot(rowId, lotInterventionId, createRoute) {
        const lotInterventions = await this.lotInterventionsRepository.relatedTo(lotInterventionId);

        if (lotInterventions.length === 0) {
            return this.appContainer.router.navigateToRoute(createRoute, { duplicatingId: rowId });
        }

        this.dialogService.open({
                viewModel: CopyToAnotherLotModal,
                model:     {
                    lotInterventionId: lotInterventionId,
                },
            })
            .whenClosed(response => {
                if (!response.wasCancelled) {
                    this.appContainer.router.navigateToRoute(createRoute, { duplicatingId: rowId, lotInterventionId: response.output });
                }
            });
    }

    /**
     * Executes a given action if it is selected at least one row
     *
     * @param action
     * @returns {*}
     */
    async ensureSelectedRows(action) {
        if (!this.datatable.instance.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        return Promise.resolve(action());
    }
}
